import React, { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { globalHistory } from '@reach/router';
import { useMutation, useQuery } from '@apollo/react-hooks';
import * as Sentry from '@sentry/gatsby';
import { getCookie } from '@services/cookies';
// context
import { GlobalContext } from '@store/global-state';
// services
import {
  trackGoogleAdsConv,
  trackEvent,
  trackIdentify,
  trackAdalyser,
  trackConversionAWIN,
  parseAWINCommissionGroup,
  trackFacebook,
  trackReddit,
  trackTiktok,
  trackPinterest,
  trackAdalyserEvent,
  getEventId,
  trackVWOEvent,
} from '@services/tracking/tracking';
import { getSessionId } from '@services/session';
import { trackGAEvent } from '@services/tracking/ga';
import { formatStringToBoolean } from '@services/format';
import { isEmpty, isSSR } from '@services/global';
import { getOrderId, shouldClearBasket } from '@services/basket';
// components
import Layout from '@components/layout';
import SEO from '@components/seo';
import { useForm } from 'react-hook-form';
import SubmitButton from '@components/submit-button';
import Loader from '@components/loader';
import PhoneNumberInput from '@components/form/phone-number-input';
// queries
import { USER_DETAILS, UPDATE_USER_DETAILS, USER_MARKETING_CONSENT, GOALS } from '@queries/user';
import { ORDER } from '@queries/orders';
import { GET_SHIPPING_ADDRESSES } from '@queries/delivery';
// interfaces
import { IUserDetails } from '@interfaces/user';
// constants
import { ERROR_MESSAGES, LANA_AI_EXAMPLE_QUERIES } from '../../constants';
// hooks
import { useGetUserDetails } from '@hooks/useGetUserDetails';
// styles
import styles from './confirmation.module.scss';
import couponCodeService from '@src/utils/couponCodeService';
import { FetchResult } from 'apollo-boost';
import { Button } from 'vitl-component-library';
import { useFeatureFlags } from '@src/hooks/useFeatureFlags';

const CheckoutConfirmation: React.FC = () => {
  const content = useStaticQuery(graphql`
    query Checkout {
      directusCheckout {
        page_title
        page_description
        confirmation_title
        confirmation_subtitle
        confirmation_news_title
        confirmation_news_phone_placeholder
        confirmation_news_button_label
        sms_subscribed_message
      }
    }
  `);
  const { setErrorModalMessage, isLoggedIn, isCheckLoginTried, setShowBasket } = useContext(
    GlobalContext
  );

  const [ personalisedProductInOrder, setPersonalisedProductInOrder ] = useState(false);
  const [ aiCtaEnabled, setAiCtaEnabled ] = useState(false);
  const { getFeatureFlags, dataFeatureFlags } = useFeatureFlags();

  useEffect(() => {
    if (!dataFeatureFlags)  getFeatureFlags();
    setAiCtaEnabled( dataFeatureFlags?.config_featureFlags.includes('lana_ai_cta_order_confirmation') );
  }, [dataFeatureFlags]);

  const openAiUrl = () => {
    var currentHost = window.location.hostname;
    if (currentHost === 'localhost') {
      currentHost = 'dev.vitl.com';
    }
    const url = `//ai.${currentHost}`;
    trackEvent('order_confirmation_ai_cta_clicked', {});
    window.open(url, '_blank');
  };

  const { removeOffer } = couponCodeService();

  // queries
  const { data: orderData, loading: loadingOrderData } = useQuery(ORDER, {
    variables: { id: getOrderId() },
    fetchPolicy: 'no-cache',
    onError: () => {
      getOrderId &&
        Sentry.captureMessage(`Order confirmation page unable to load order id: ${getOrderId()}`);
      setErrorModalMessage(ERROR_MESSAGES.getOrder);
    },
  });

  const {
    id: orderId,
    total,
    discountCode,
    currency,
    status,
    awinParts,
    awinTotal,
    itemCount,
    shipping,
    items,
  } = orderData?.user_order || {};

  const { loading: loadingGoals, error: errorGoals, data: dataGoals } = useQuery(GOALS);
  const topGoal = useMemo(() => {
    const healthGoals = dataGoals?.health_goals;
    if (!healthGoals || healthGoals.length === 0) {
      return null;
    }
    return healthGoals[0];
  }, [dataGoals]);

  // examples for top goals
  const topGoalExamples = useMemo(() => {
    const goal = topGoal;
    if (!goal) {
      return null;
    }
    const goalExamples: string[] | undefined = (LANA_AI_EXAMPLE_QUERIES as any)[goal.id];
    // return 3 random examples
    if (goalExamples) {
      const shuffled = goalExamples.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, 3);
    } else {
      return null;
    }
    
  }, [topGoal]);

  const { user, loading: loadingUserDetails } = useGetUserDetails();

  const { data: dataShippingAddresses } = useQuery(GET_SHIPPING_ADDRESSES, {
    fetchPolicy: 'network-only',
  });

  const { data: dataMarketingConsent, loading: loadingMarketingConsent } = useQuery(
    USER_MARKETING_CONSENT
  );

  // mutations
  const [
    updateUserDetails,
    { data: updateResponse, loading: loadingUpdateUserDetails, error: errorUpdateUserDetails },
  ] = useMutation(UPDATE_USER_DETAILS, {
    update: (
      cache,
      updateResponse: FetchResult<{ user_updateDetails: IUserDetails; }>
    ) => {
      const data = updateResponse.data?.user_updateDetails;
      if (data) {
        cache.writeQuery({ query: USER_DETAILS, data });
      }
    },
    onCompleted: res => {
      if (res.user_updateDetails?.customerDataPlatformId) {
        trackIdentify(
          res.user_updateDetails.customerDataPlatformId,
          {
            smsSubscribed: true,
            phone: res.user_updateDetails.phone,
          },
          {
            email: res.user_updateDetails.email,
            firstName: res.user_updateDetails.firstName,
            lastName: res.user_updateDetails.lastName,
            phone: res.user_updateDetails.phone,
          }
        );
        trackEvent('SubscribedToSMSMarketing');
      }
    },
    onError: () => {
      setErrorModalMessage(ERROR_MESSAGES.updatePhoneNumber);
    },
  });

  const hasUserAPhoneNumber = user?.phone;

  const isUserSubscribedToSMS = dataMarketingConsent?.user_marketingConsent.sms;

  const getLastName = () => {
    const firstName = user?.firstName;
    const lastName = user?.lastName;

    if (lastName) return lastName;

    if (!lastName && dataShippingAddresses?.user_deliveryAddresses.length > 0) {
      const addressMatchingFirstName = dataShippingAddresses.user_deliveryAddresses.find(
        (address: any) => {
          address.firstName === firstName;
        }
      );

      if (addressMatchingFirstName) return addressMatchingFirstName.lastName;

      return dataShippingAddresses.user_deliveryAddresses[
        dataShippingAddresses.user_deliveryAddresses.length - 1
      ].lastName;
    }

    return '.';
  };

  const { handleSubmit, errors, control } = useForm();

  const handleNewSubmit = (formData: any) => {
    isEmpty(errors) &&
      updateUserDetails({
        variables: {
          phone: formData.phone,
        },
      });
  };

  useEffect(() => {
    if (!isLoggedIn && isCheckLoginTried) navigate('/account/signin');

    if (shouldClearBasket()) {
      setShowBasket(false);
    }
  }, [isCheckLoginTried, isLoggedIn]);

  useEffect(() => {
    if (orderId && status === 'processing' && total !== undefined) {

      removeOffer('all');

      const eventId = getEventId();

      // conversion tracking
      trackGoogleAdsConv(orderId, total, currency);
      const awinCommissionGroup = parseAWINCommissionGroup(awinParts);
      if (awinCommissionGroup) {
        trackConversionAWIN(orderId, awinCommissionGroup.total, currency, discountCode, awinCommissionGroup.string);
      }

      trackReddit('Purchase', {
        currency,
        itemCount,
        transactionId: orderId,
        value: total,
      });

      trackTiktok('CompletePayment', {
        content_type: 'product_group',
        content_id: orderId,
        quantity: itemCount,
        currency,
        value: total,
      }, eventId);

      trackTiktok('PlaceAnOrder', {
        content_type: 'product_group',
        content_id: orderId,
        quantity: itemCount,
        currency,
        value: total,
      }, eventId);

      trackPinterest('Checkout', {
        order_id: orderId,
        order_quantity: itemCount,
        currency,
        value: total,
      });

      trackFacebook('Purchase', { currency, value: total }, eventId);

      trackEvent('FE Order Completed', {
        order_id: orderId,
        order_quantity: itemCount,
        currency,
        value: total,
        coupon: discountCode,
        shipping,
        items,
      });

      const awinAWC = getCookie('awinAWC') ?? window.AWIN?.Tracking?.sCookiesString;

      trackEvent(
        'Order Completed',
        {
          order_id: orderId,
          order_quantity: itemCount,
          currency,
          revenue: total,
          value: total,
          coupon: discountCode,
          shipping,
          items,
          awinAWC,
          awinCommissionGroup: awinCommissionGroup?.group,
          awinTotal: awinCommissionGroup?.total,
        },
        ['facebook'],
        eventId
      );

      // Specific "Purchase" event for Google custom conversion event.
      trackGAEvent(
        'Purchase',
        {
          order_id: orderId,
          order_quantity: itemCount,
          currency,
          value: total,
          coupon: discountCode,
          shipping,
          items,
          session_id: getSessionId(),
        }
      );

      // Specific "CompletePayment" event for TikTok custom conversion event.
      // Ruderstack converts any "Checkout Step Completed" event to the
      //  "CompletePayment" event in TikTok, which is not desirable, so we are
      //  firing this event manually to aid with backend-sourced conversion.
      // https://ads.tiktok.com/help/article/standard-events-parameters?lang=en
      trackEvent(
        'CompletePayment',
        {
          order_id: orderId,
          order_quantity: itemCount,
          currency,
          revenue: total,
          value: total,
          coupon: discountCode,
          shipping,
          items,
        },
        ['tiktok'],
        eventId
      );

      trackVWOEvent('purchase', total);

      const containsPersonalisedSku = items.some(item => 
        item.sku.includes('personalised') || item.sku.includes('essential-one')
      );

      if (containsPersonalisedSku) {
        setPersonalisedProductInOrder(true);
      }

      if (personalisedProductInOrder && aiCtaEnabled) {
        trackEvent('order_confirmation_ai_cta_view', {});
      }

      let listenToHistoryChange: { (): any; (): void };

      if (process.env.GATSBY_ENV === 'production') {
        trackAdalyser();
        total && trackAdalyserEvent(total);
        listenToHistoryChange = globalHistory.listen(({ action }) => {
          if (action === 'PUSH') {
            (window as any).adalyserTracker('trackSession', 'lce1');
          }
        });

        return () => {
          listenToHistoryChange();
        };
      }
    }
  }, [orderData]);

  const loading =
    loadingOrderData ||
    loadingUserDetails ||
    !content ||
    loadingMarketingConsent;

  if (loading) return <Loader />;

  const awinCommissionGroup = parseAWINCommissionGroup(awinParts);
  const awinVoucherQueryParamString = discountCode ? `&vc=${discountCode}` : '';

  return (
    <Layout showFooter={false}>
      <SEO
        title={content.directusCheckout.page_title}
        description={content.directusCheckout.page_description}
      />
      <div className={styles.container}>
        {!isSSR && (
          <Suspense fallback={<Loader />}>
            <section className={styles.sectionCheckout}>
              <div className={styles.container}>
                <div className={styles.row}>
                  <div className={styles.confirmation}>
                    <div className={styles.confirmationTitle}>
                      <img
                        className={styles.checkMarkImage}
                        src="/images/order-checkmark.svg"
                        alt=""
                      />
                      <h1>{content.directusCheckout.confirmation_title}</h1>
                    </div>
                    <p className={styles.text}>
                      {content.directusCheckout.confirmation_subtitle.replace('{here}', '')}
                      <button className={styles.link} onClick={() => navigate('/account/orders')}>
                        here
                      </button>
                      .
                    </p>

                  {(!hasUserAPhoneNumber || !isUserSubscribedToSMS) && (
                    <form onSubmit={handleSubmit(handleNewSubmit)} className={styles.phoneFormCheckout}>
                      <h2>Receive Vitl offers & news on your phone</h2>
                      <div className={styles.textInputWrapper}>
                        <PhoneNumberInput
                          id="phone"
                          autoComplete="off"
                          control={control}
                          countryCallingCodeEditable={false}
                          error={errors.phone}
                          required
                          showLabel={false}
                        />
                        <button
                        onClick={handleSubmit(handleNewSubmit)}
                        disabled={loadingUpdateUserDetails}
                        >Save</button>
                        {errorUpdateUserDetails && (
                        <div className={styles.textInputError}>
                          {errorUpdateUserDetails}
                        </div>
                        )}
                        {(updateResponse) && (
                        <div className={styles.textInputSuccess}>
                          {content.directusCheckout.sms_subscribed_message}
                        </div>
                        )}
                      </div>
                    </form> 
                  )}
                  </div>
                </div>

                {personalisedProductInOrder && aiCtaEnabled && (
                  <div className={styles.row}>
                    <div className={styles.aiChatAd} onClick={openAiUrl}>
                      <div className={styles.aiChatAdTitle}>
                        <img
                          src="/images/icon_lana_ai.svg"
                          alt="LANA AI icon" />
                        <h2>Meet LANA</h2>
                        <span className={styles.betaTag}>Beta</span>
                      </div>
                      <h3>Your personal AI Nutritionist</h3>
                      <ul className={styles.aiChatAdQuestions}>
                        {topGoalExamples && (
                          <>
                          {topGoalExamples.map((example: string) => (
                            <li><p>{example}</p></li>
                          ))}
                          </>
                        )}
                        {!topGoalExamples && (
                          <>
                          <li><p>How will my pack help me?</p></li>
                          <li><p>What foods will support my goals?</p></li>
                          <li><p>Which healthy habits can I adopt?</p></li>
                          </>
                        )}
                      </ul>
                      <div className={styles.textfieldCTA}>
                        Ask me something
                      </div>
                    </div>
                  </div>
                )}

                {orderId && awinCommissionGroup && (
                  <img
                    className={styles.awinPixel}
                    alt=""
                    src={`https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=${
                      process.env.GATSBY_AWIN_ADVERTISER_ID
                    }&amount=${awinCommissionGroup.total}&ch=aw&parts=${awinCommissionGroup.string}&ref=${orderId}&cr=${currency}${awinVoucherQueryParamString}&testmode=${Number(
                      formatStringToBoolean(process.env.GATSBY_AWIN_TEST_ENABLED)
                    )}`}
                  />
                )}
              </div>
            </section>
          </Suspense>
        )}
      </div>
    </Layout>
  );
};

export default CheckoutConfirmation;
